import React from 'react'
import Site from 'components/site'
import Page from 'components/page'
import PageHeader from 'components/page/page-header'
import useLatestPost from 'hooks/use-latest-post'
import useSite from 'hooks/use-site'
import styles from './index.module.css'

const Index = ({ path }) => {
  //const mdx = useLatestPost()
  const { siteMetadata } = useSite()
  const { enableBlog } = siteMetadata.internalSettings

  return (
    <Site path={path} disableFooter={false} disableHeader={false}>
      <Page className={styles.index}>
        <div className="grid">
          <div className="grid-span-12">
            <div className={styles.indexHeader}>
              <h1 className={styles.indexTitle}>Patrick Burtchaell</h1>
            </div>
            <div className="grid-hide-on-small">
              <ul className={styles.indexList}>
                <li>I work on Messenger design systems and app architecture at Meta</li>
                <li>Previously, I worked on privacy and end-to-end encryption</li>
                <li>I also maintain a few open source projects on <a href={siteMetadata.github} target="_blank" rel="noopener noreferrer">GitHub</a></li>
                <li>I'm based in New Orleans and previously lived in San Francisco</li>
              </ul>
            </div>
          </div>
        </div>
      </Page>
    </Site >
  )
}

export default Index
